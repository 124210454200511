import { Component } from 'react';

import ClientStore from 'store/Client';
import RouteManager from 'routes/manager';

export class Home extends Component {
  private clientStore = ClientStore;

  componentDidMount() {
    const { isLoggedIn } = this.clientStore;
    if (isLoggedIn) {
      RouteManager.goToDashboard();
    } else {
      RouteManager.goToLogin();
    }
  }

  render() {
    return null;
  }
}
